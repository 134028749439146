.table {
    width: 100%;
}

.tableContainer {
    overflow-x: auto; /* Ensure the container can scroll horizontally if needed */
}

.tableList {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%; /* Ensure the table takes full width of its container */
}

.tableListItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer; /* Add cursor pointer to indicate clickable rows */
}

.tableListItem.header {
    font-weight: bold;
    background-color: #f2f2f2;
}

.tableListItem div {
    flex: 1;
    text-align: center;
    padding: 5px 10px;
    word-wrap: break-word; /* Ensure long words break */
    white-space: pre-wrap; /* Ensure text wraps */
}

.tableListItem.selected {
    background-color: #e0f7fa; /* Highlight selected row */
}

.tableListItem input[type="checkbox"] {
    cursor: pointer;
}

.editFlagTextarea {
    width: 100%;
    min-height: 40px; /* Minimum height */
    resize: none; /* Disable manual resizing */
    overflow: hidden; /* Hide overflow */
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
}
