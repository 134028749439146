.leftbar {
    position: fixed;
    top: 50px;
    left: 0;
    width: 250px;
    height: calc(100vh - 50px);
    background-color: #fff;
    box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
    -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
    z-index: 1000;
}

.leftbarWrapper {
    padding: 20px;
}

.leftbarList {
    list-style-type: none;
    padding: 0;
    margin: 0;
    object-fit: cover;
}

.leftbarListItem {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.leftbarListItem:hover {
    background-color: orange;
}

.leftbarIcon {
    margin-right: 5px;
}

.leftbarListIemText {
    font-size: 16px;
    font-weight: 500;
}
