@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

.lobby-screen * {
    box-sizing: border-box;
}

.lobby-screen {
    background: #f6f5f7;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;
    margin: -20px 0 50px;
    height: 100vh;
}
