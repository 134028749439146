.pkgs {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: calc(100vh - 50px);
  background-color: #f6f5f7;
  padding: 20px;
  margin-left: 250px; /* Adding space for the left sidebar */
  box-sizing: border-box;
  overflow-y: auto;
  width: calc(100% - 250px); /* Occupy remaining width */
}

.pkgsWrapper {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
}

.pkgsTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.packageListing {
  font-size: 24px;
  font-weight: 600;
}

.addPackageBtn {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  padding: 10px 20px;
  max-width: 300px;
}

.addPackageBtn:hover {
  background-color: #0056b3;
}

.pkgsBottom {
  display: flex;
  flex-direction: column;
}

.pkgsTable {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Ensure the table layout is fixed */
}

.appStatusDropDown {
  border: 1px solid #007bff;
  padding: 5px 10px;
  transition: border-color 0.3s, box-shadow 0.3s;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.pkgsTable th,
.pkgsTable td {
  padding: 15px;
  text-align: left;
  border: 1px solid #ddd;
  word-break: break-all; /* Ensure long words break */
  white-space: normal; /* Ensure text wraps */
  overflow-wrap: break-word; /* Ensure long words wrap */
}

.pkgsTable th {
  background-color: #f2f2f2;
  font-weight: 600;
}

.clickable {
  color: #026ada;
}

.pkgListItemRight {
  display: flex;
  gap: 15px;
  justify-content: center;
}

.pkgListItemRight .MuiSvgIcon-root {
  cursor: pointer;
  transition: transform 0.3s;
}

.pkgListItemRight .MuiSvgIcon-root:hover {
  transform: scale(1.2);
}

.pkgSaveBtns,
.pkgCancelBtns {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 2px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.pkgCancelBtns {
  background-color: #007bff;
}

.editPkgTextarea {
  width: 100%;
  min-height: 40px; /* Minimum height */
  resize: none; /* Disable manual resizing */
  overflow: hidden; /* Hide overflow */
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}
