.topbarContainer {
  height: 50px;
  width: 100%;
  background-color: orange;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 999;
}

.logo {
  font-size: 24px;
  margin-left: 20px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.topbarRight{
  display: flex;
  align-items: center;
}

.logoutBtn {
  background-color: rgb(223, 28, 28);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  padding: 3px 20px;
  margin: 10px 10px;
}

.userName {
  color: white;
  font-weight: bold;
  font-size: large;
  margin: 10px 10px;
  margin-bottom: 10px;
}
