.flgs {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: calc(100vh - 50px);
    background-color: #f6f5f7;
    padding: 20px;
    margin-left: 250px; /* Adding space for the left sidebar */
    box-sizing: border-box;
    overflow-y: auto;
    width: calc(100% - 250px); /* Occupy remaining width */
}

.flgsWrapper {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    box-sizing: border-box;
}

.form-select {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #007bff;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.form-select:hover,
.form-select:focus {
    border-color: #0056b3;
    box-shadow: 0 0 8px rgba(0, 91, 187, 0.2);
}

.flgsTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.flagListing {
    font-size: 24px;
    font-weight: 600;
}

.addFlagBtn {
    background-color: #007bff;
    color: #fff;
    border: none;
    max-width: 200px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.addFlagBtn:hover {
    background-color: #0056b3;
}

.flgsBottom {
    display: flex;
    flex-direction: column;
}

.flgsTable {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Ensure the table layout is fixed */
}

.flgsTable th,
.flgsTable td {
    padding: 15px;
    text-align: left;
    border: 1px solid #ddd;
    word-break: break-all; /* Ensure long words break */
    white-space: normal; /* Ensure text wraps */
    overflow-wrap: break-word; /* Ensure long words wrap */
}

.flgsTable th {
    background-color: #f2f2f2;
    font-weight: 600;
}

.flagListItemRight {
    display: flex;
    gap: 15px;
    justify-content: center;
}

.flagListItemRight .MuiSvgIcon-root {
    cursor: pointer;
    transition: transform 0.3s;
}

.flagListItemRight .MuiSvgIcon-root:hover {
    transform: scale(1.2);
}

.associatedPackages {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
    margin-top: 10px;
}

.associatedPackageItem {
    background-color: #d9d9d9;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
}

.flgSaveBtns {
    background-color: #4caf50;
    border: none;
    color: white;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 2px 2px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.flgCancelBtns {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 2px 2px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.editFlagTextarea {
    width: 100%;
    min-height: 40px; /* Minimum height */
    resize: none; /* Disable manual resizing */
    overflow: hidden; /* Hide overflow */
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
}
